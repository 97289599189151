import {
  AnimationDelay,
  ExpandAnimation,
} from '@/components/atoms/ExpandAnimation/ExpandAnimation';
import { useAnalytics } from '@/components/molecules/AnalyticsProvider';
import { AnalyticsEvent } from '@/lib/handleActionTracking';
import { ChevronDownIcon, ChevronUpIcon } from '@heroicons/react/24/outline';
import { MinusIcon, PlusIcon } from '@heroicons/react/24/solid';
import React, { useEffect } from 'react';

interface AccordionIconProps {
  id: string;
  variant?: 'small' | 'medium' | 'large';
  className?: string;
}

export interface AccordionProps {
  children: React.ReactNode;
  className?: string;
  title: React.ReactNode;
  expanded?: boolean;
  setExpanded?: (expanded: boolean) => void;
  analyticsEvent?: AnalyticsEvent;
  buttonClassName?: string;
  icon?: AccordionIconProps | null;
  disabled?: boolean;
  animationDelay?: AnimationDelay;
  expandOnClick?: boolean;
}

const Accordion: React.FC<AccordionProps> = ({
  title,
  children,
  expanded = false,
  setExpanded,
  analyticsEvent,
  icon,
  buttonClassName = '',
  disabled,
  animationDelay,
  expandOnClick = true,
  ...props
}) => {
  const bodyEl = React.useRef<HTMLElement>(null);
  const { handleActionTracking } = useAnalytics();
  const [isLocalExpanded, setIsLocalExpanded] = React.useState(expanded);

  useEffect(() => {
    setIsLocalExpanded(expanded);
  }, [expanded, setIsLocalExpanded]);

  let iconSize = 'h-xl w-xl';
  if (icon?.variant === 'small') {
    iconSize = 'h-l w-l';
  } else if (icon?.variant === 'medium') {
    iconSize = 'h-[20px] w-[20px]';
  }

  let ExpandIcon: JSX.Element;
  const expandIconClass = `${icon?.className} ${iconSize} h-6 w-6 flex-none`;

  if (icon?.id === 'chevron') {
    if (isLocalExpanded) {
      ExpandIcon = <ChevronUpIcon className={expandIconClass} />;
    } else {
      ExpandIcon = <ChevronDownIcon className={expandIconClass} />;
    }
  } else if (isLocalExpanded) {
    ExpandIcon = <MinusIcon className={expandIconClass} />;
  } else {
    ExpandIcon = <PlusIcon className={expandIconClass} />;
  }
  return (
    <article
      {...props}
      className={`${props.className} ${disabled ? 'opacity-50' : ''}`}
    >
      <div
        className={`mb-2 flex w-full items-center justify-between px-0 text-left ${expandOnClick ? '' : 'cursor-default'} ${buttonClassName} `}
        aria-label={`${isLocalExpanded ? 'Collapse' : 'Expand'}`}
        role="button"
        onClick={() => {
          if (disabled) return;
          if (!isLocalExpanded && analyticsEvent) {
            handleActionTracking(analyticsEvent);
          }
          setExpanded?.(!isLocalExpanded);
          if (expandOnClick) setIsLocalExpanded(!isLocalExpanded);
        }}
      >
        {title}
        {!disabled && icon !== null && ExpandIcon}
      </div>
      <section>
        <ExpandAnimation delay={animationDelay} expanded={isLocalExpanded}>
          <section ref={bodyEl}>{children}</section>
        </ExpandAnimation>
      </section>
    </article>
  );
};

export default Accordion;
