import * as Slider from '@radix-ui/react-slider';
import React from 'react';

export enum SizeVariants {
  Desktop = 'Desktop',
  Mobile = 'Mobile',
}

const SizeDetails = {
  [SizeVariants.Desktop]: { trackHeight: 'h-xs', thumbSize: 'w-xl h-xl' },
  [SizeVariants.Mobile]: {
    trackHeight: 'h-s',
    thumbSize: 'w-3xl h-3xl',
  },
};

interface RangeSliderProps {
  min: number;
  max: number;
  step: number;
  defaultValue: number[];
  size: SizeVariants;
  onValueChange: (values: number[]) => void;
  values: number[];
  setAllowTouchMove?: (allowTouchMove: boolean) => void;
}

const RangeSlider: React.FC<RangeSliderProps> = ({
  min,
  max,
  step,
  defaultValue = [0],
  size = SizeVariants.Desktop,
  onValueChange,
  values,
  setAllowTouchMove,
}) => (
  <form className="flex flex-grow">
    <Slider.Root
      className={`relative flex h-xl w-full touch-none select-none items-center`}
      defaultValue={defaultValue}
      min={min}
      max={max}
      step={step}
      value={values}
      onValueChange={onValueChange}
    >
      <Slider.Track
        className={`relative ${SizeDetails[size].trackHeight} grow rounded-full bg-neutral-300`}
      >
        <Slider.Range className="absolute h-full rounded-full bg-brandMidnight" />
      </Slider.Track>
      {defaultValue.map((_, index) => (
        <Slider.Thumb
          key={index}
          className={`block ${SizeDetails[size].thumbSize} cursor-pointer rounded-full border-[2px] border-white bg-brandMidnight outline-none hover:bg-brandMidnight-hover active:border-brandMidnight active:bg-brandElectric`}
          onTouchStart={() => setAllowTouchMove?.(false)}
          onTouchEnd={() => setAllowTouchMove?.(true)}
          onMouseEnter={() => setAllowTouchMove?.(false)}
          onMouseLeave={() => setAllowTouchMove?.(true)}
        />
      ))}
    </Slider.Root>
  </form>
);

export default RangeSlider;
